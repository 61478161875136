import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDlvii7VjA_Wwi2ANc9oNiuVL20cZ8AGKs",
  authDomain: "vo2-nutrition.firebaseapp.com",
  projectId: "vo2-nutrition",
  storageBucket: "vo2-nutrition.appspot.com",
  messagingSenderId: "975539387933",
  appId: "1:975539387933:web:e9c7ca230b7464e4e750f4"
};

export const firebaseApp =  firebase.initializeApp(firebaseConfig);