import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import car1 from "../assets/img/car1.JPG";
import car2 from "../assets/img/car2.PNG";
import car3 from "../assets/img/car3.JPG";
import car4 from "../assets/img/car4.JPG";
import '../styles/App.css';


function Car() {
    return (
        <>
        <div id="carouselExampleCaptions" class="carousel slide " data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={car1} class="d-block img-fluid" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
        <h5>  </h5>
        <p>  </p>
      </div>
    </div>
    <div class="carousel-item">
      <img src={car4} class="d-block img-fluid" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
        <h5> </h5>
        <p> </p>
      </div>
    </div>
    <div class="carousel-item">
      <img src={car3} class="d-block img-fluid" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
        <h5>  </h5>
        <p>   </p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
        </>
    );
    }
export default Car;
