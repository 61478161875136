import React, {useState, useEffect} from 'react';
import '../styles/Form.css';
import toast, { Toaster } from 'react-hot-toast';
import { firebaseApp } from "../firebase/Firebase";
import firebase from "firebase/app";
import "firebase/firestore";
const db = firebase.firestore(firebaseApp);

export default function Notificaciones({setNotifi, setFlag}) {

    const [formData, setFormData] = useState(defaultFormValue());
    const [totalData, setTotalData] = useState();
      const [users, setUsers] = useState("");
      const [correoUser, setCorreoUser] = useState("");



    useEffect(() => {
            const correos = [];
          db.collection("usuarios").orderBy("nombre", "asc")
              .get()
              .then((response) => {
                  setUsers(response.docs[response.docs.length - 1]);
                response.forEach((doc) => {
                  const correo = doc.data();
                  correo.id = doc.id;
                  correos.push(correo);
                });
                setCorreoUser(correos);
              });
  
          db.collection("citas")
          .get()
          .then((snap) => {
            setTotalData(snap.size);
          });  
    }, [])


    const volver = () => {
        setNotifi(false);
        setFlag(false);
      }

    const onChange = (e) => { 
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const notificacionPersonalizada = () => {
        console.log(totalData);
    for (let i = 0; i <= totalData; i++){
      console.log("dentro");
      console.log(correoUser[i].token)
        fetch('https://exp.host/--/api/v2/push/send', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          Accept: 'application/json',
          'Accept-encoding': 'gzip, deflate',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sound: "default",
          to:`${correoUser[i].token}`,
          title: `${formData.titulo}`,
              body:  `${formData.descripcion}`,
          data: { data: 'Vamos..' },
          _displayInForeground: true,
        }),
      });
    }
    toast.success('Notificaciones enviadas');
    setNotifi(false);
    setFlag(false);
      }

  return (
    <>
    <div class="registro">
    <Toaster
  position="bottom-right"
  reverseOrder={false}
  toastOptions={{
    className: '',
    duration: 1000,}}
/>
                <div class="col col-md col-lg">
                <h1>Notificación</h1>
              <div style={{textAlign: "center"}}>
                  <input style={{width: "51%"}}  type="text" onChange={onChange} name="titulo" placeholder='Título de la notificación' required/>
                  <input  style={{marginTop: 50, width: "51%", marginBottom: 50}} type="text" onChange={onChange} name="descripcion" placeholder='Descripción de la notificación' required/>
                  </div>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn  me-md-2" onClick={notificacionPersonalizada} type="submit">Enviar notificación</button>
        <button class="btn" type="button" onClick={volver}>Volver</button>
        </div>
    </div>
      </div>
    </>
  )
}

function defaultFormValue(){
    return{
        titulo: "",
        descripcion: "",
    }
}