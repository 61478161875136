import React from 'react';
import {Navbar} from '../navbar/Navbar';
import Car from './Car';
import Modos from './Modos';
import Pie from './Pie';
import YoSoy from './YoSoy';
import "bootstrap/dist/css/bootstrap.min.css";
import Referencias from './Referencias';

const PantallaInicio = ({history}) => {
    return (
        <>
   <Navbar/>
   <Car history={history}/>
   <Modos history={history}/>
   <YoSoy history={history}/>
   <Referencias history={history}/>
   <Pie history={history}/>
        </>
    )
}

export default PantallaInicio;
