import React from 'react';
import foto from "../assets/img/perfil.jpeg";


export const Estadisticas = () => {
    return (
        <>
            <section class="acerca-sobremi">

<h1 class="encabezado">Acerca</h1>

<div class="hilera-sobremi">

    <div class="barra-progreso">
        <h3 class="titulo-sobremi">Experiencia</h3>

        <div class="progreso">
            <h3>sobre peso <span>100%</span></h3>
            <div class="barra-mi"><span></span></div>
        </div>

        <div class="progreso">
            <h3>fitness <span>85%</span></h3>
            <div class="barra-mi"><span></span></div>
        </div>

        <div class="progreso">
            <h3>salud <span>60%</span></h3>
            <div class="barra-mi"><span></span></div>
        </div>
    </div>

    <div class="info-personal">
        <h3 class="titulo-sobremi">Información</h3>

        <div class="box-container-sobremi">
            <div class="box-sobremi">
                <h3><span>Nombre: </span>Giovanna Rangel Murillo</h3>
                <h3><span>Email: </span>nutricion.gio@hotmail.com</h3>
                <h3><span>Número: </span>3333910334</h3>
                <h3><span>Dirección: </span>Av del valle 1705, local 5 (planta alta), Jardines del Valle</h3>
            </div>

            <div class="box-sobremi">
                
                
            </div>    
        </div>
    </div>

</div>

<div class="contador">

    <div class="box-sobremi">
        <h3>6+</h3>
        <p>Años de Experiencia</p>
    </div>

    <div class="box-sobremi">
        <h3>500+</h3>
        <p>Clientes felices</p>
    </div>

    <div class="box-sobremi">
        <h3>1000+</h3>
        <p>Consultas</p>
    </div>

    <div class="box-sobremi">
        <h3>2+</h3>
        <p>Titulos</p>
    </div>
</div>

</section>

        </>
    )
}
