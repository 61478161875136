import "bootstrap/dist/css/bootstrap.min.css";

import { AppRouter } from "./routers/AppRouter";

function App() {
  return (
    <>
   <AppRouter/>
  </>
  );
}

//<Referencias/>
export default App;
