import React from 'react';
import '../styles/contInicio.css';
import qr from "../assets/img/qrapp.PNG";


function Pie({history}) {

    function inicio() {
        history.push("/inicio");
    }
    function sobremi() {
        history.push("/Sobre_mi");
    }
    function servicios() {
        history.push("/servicios");
    }
    function atletas() {
        history.push("/Mis_Atletas");
    }
    function vo2() {
        history.push("/VO2_NUTRITION");
    }

    return (
        <>
         <div class="pie-inicio">

<div class="box-container-caracterisiticas">

    <div class="box-caracteristicas">
        <h3 style={{color: "#fff"}}>Acerca</h3>
        <p style={{color: "#fff"}}> <br/>
👩🏻‍⚕️l Master en nutrición deportiva <br/> 
📐l ISAK 1 <br/>
🏈l Amante de los deportes <br/>
✈️l Amo viajar & te comparto mis viajes <br/>
📍l Zap, Jal</p>
    </div>


    <div class="box-caracteristicas">
        <h3 style={{color: "#fff"}}>Links</h3>
        <a style={{color: "#fff"}} onClick={inicio}>Inicio</a>
        <a style={{color: "#fff"}} onClick={sobremi}>Sobre Mi</a>
        <a style={{color: "#fff"}} onClick={servicios}>Servicios</a>
        <a style={{color: "#fff"}} onClick={atletas}>Mis Atletas</a>
        <a style={{color: "#fff"}} onClick={vo2}>VO2 NUTRITION APP</a>
    </div>

    <div class="box-caracteristicas">
        <h3 style={{color: "#fff"}}>Sigueme</h3>
        <a style={{color: "#fff"}} href="https://www.facebook.com/VO2-Nutrition-by-Giovanna-Rangel-106725840997039">Facebook</a>
        <a style={{color: "#fff"}} href="https://instagram.com/vo2nutritionbygiovannarangel?utm_medium=copy_link">Instagram</a>
        <h3 style={{color: "#fff"}}>Aplicación</h3>
        <a style={{color: "#fff"}} href="https://play.google.com/store/apps/details?id=com.marquez.vo2nutrition">Android</a>
        <a style={{color: "#fff"}} href="https://apps.apple.com/app/vo2-nutrition/id1627508686">IOS</a>
    </div>

    <div class="box-caracteristicas">
        <h3 style={{color: "#fff"}}>Contacto</h3>
        <div class="info-contacto">
            <i class="fas fa-phone"></i>
            <p style={{color: "#fff"}}>3326767837 <br/> 
            <a style={{color: "#fff"}} href="https://api.whatsapp.com/send?phone=5213326767837&text=Hola%20%E2%98%BA%EF%B8%8F%20me%20gustar%C3%ADa%20recibir%20informaci%C3%B3n%20sobre%20la%20asesor%C3%ADa%20en%20nutrici%C3%B3n%20">Whatsapp</a>
            </p>
            
        </div>

        <div class="info-contacto">
            <i class="fas fa-envelope"></i>
            <p style={{color: "#fff"}}>nutricion.gio@hotmail.com</p>
        </div>

        <div class="info-contacto">
            <a href="https://goo.gl/maps/uC1qc5PK75wsJ2zZ6" target="_blank">
            <i class="fas fa-map-marker-alt"></i></a>
           <p style={{color: "#fff"}}> Av del valle 1705,<br/> local 5 (planta alta), <br/>Jardines del Valle, <br/>45134 Zapopan, Jal.</p>
            

        </div>
        
    </div>

</div>


<div style={{color: "#fff"}} class="reservado">V02 NUTRITION</div>

</div>
        </>
    )
}

export default Pie;



/*



import React from 'react';
import '../pie.css';
import { Servicios } from '../servicios/Servicios';
import { SobreMi } from '../sobreMi/SobreMi';

function Pie({history}) {

    function inicio() {
        history.push("/inicio");
    }
    function sobremi() {
        history.push("/Sobre_mi");
    }
    function servicios() {
        history.push("/servicios");
    }
    function atletas() {
        history.push("/Mis_Atletas");
    }
    function vo2() {
        history.push("/VO2_NUTRITION");
    }

    return (
        <>
        <div class="bd">


<footer class="footer-pie">

    <div class="waves">
        <div class="wave" id="wave1"></div>
        <div class="wave" id="wave2"></div>
        <div class="wave" id="wave3"></div>
        <div class="wave" id="wave4"></div>
    </div>

    <ul class="social-icons-pie">
        <li><a href="https://www.facebook.com/VO2-Nutrition-by-Giovanna-Rangel-106725840997039"><ion-icon name="logo-facebook"></ion-icon></a></li>
        <li><a href="https://instagram.com/vo2nutritionbygiovannarangel?utm_medium=copy_link"><ion-icon name="logo-instagram"></ion-icon></a></li>
    </ul>

    <ul class="menu-pie">
        <li><a onClick={inicio} >Inicio</a></li>
        <li><a onClick={sobremi}>Sobre mi</a></li>
        <li><a onClick={servicios}>Servicios</a></li>
        <li><a onClick={atletas}>Mis atletas</a></li>
        <li><a onClick={vo2}>VO2NUTRITION APP</a></li>
    </ul>

   <p> Av del valle 1705, local 5 (planta alta) Jardines del Valle, 
       45134 Zapopan, Jal.</p>

</footer>

</div>
        </>
    )
}

export default Pie;
*/
