import React from 'react';


export const Plan = ({dataPlan, setFlag, setPlan}) => {
    const volver = () => {
        setPlan(false);
        setFlag(false);
      }
    return (
        <>
         <div class="table-responsive">
        <h1 style={{color: "#004085", textAlign: "center",  marginTop: 30}}>Desayuno</h1>
          <table class="table">
  <thead style={{background: "#f2f2f2",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Fruta</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Verdura</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Cereales</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Leguminosas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Lácteos</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Proteínas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Grasas</th>
      
    </tr>
  </thead>
  <tbody style={{background: "#FFFFFF",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="row">{dataPlan.desayuno_fruta}</th>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.desayuno_verdura}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.desayuno_cereal}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.desayuno_leguminosa}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.desayuno_lacteos}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.desayuno_proteina}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.desayuno_grasa}</td>
    </tr>
  </tbody>
</table>
</div>
<br/>
<br/>
<div class="table-responsive">
        <h1 style={{color: "#004085", textAlign: "center"}}>Media mañana -Colación</h1>
          <table class="table">
  <thead style={{background: "#f2f2f2",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Fruta</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Verdura</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Cereales</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Leguminosas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Lácteos</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Proteínas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Grasas</th>
      
    </tr>
  </thead>
  <tbody style={{background: "#FFFFFF",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="row">{dataPlan.cm_fruta}</th>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cm_verdura}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cm_cereal}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cm_leguminosa}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cm_lacteos}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cm_proteina}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cm_grasa}</td>
    </tr>
  </tbody>
</table>
</div>
<br/>
<br/>
<div class="table-responsive">
        <h1 style={{color: "#004085", textAlign: "center"}}>Comida</h1>
          <table class="table">
  <thead style={{background: "#f2f2f2",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Fruta</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Verdura</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Cereales</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Leguminosas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Lácteos</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Proteínas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Grasas</th>
      
    </tr>
  </thead>
  <tbody style={{background: "#FFFFFF",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="row">{dataPlan.comida_fruta}</th>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.comida_verdura}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.comida_cereal}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.comida_leguminosa}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.comida_lacteos}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.comida_proteina}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.comida_grasa}</td>
    </tr>
  </tbody>
</table>
</div>
<br/>
<br/>
<div class="table-responsive">
        <h1 style={{color: "#004085", textAlign: "center"}}>Media tarde -Colación</h1>
          <table class="table">
  <thead style={{background: "#f2f2f2",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Fruta</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Verdura</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Cereales</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Leguminosas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Lácteos</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Proteínas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Grasas</th>
      
    </tr>
  </thead>
  <tbody style={{background: "#FFFFFF",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="row">{dataPlan.cm_fruta}</th>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cv_verdura}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cv_cereal}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cv_leguminosa}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cv_lacteos}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cv_proteina}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cv_grasa}</td>
    </tr>
  </tbody>
</table>
</div>
<br/>
<br/>
<div class="table-responsive">
        <h1 style={{color: "#004085", textAlign: "center"}}>Cena</h1>
          <table class="table">
  <thead style={{background: "#f2f2f2",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Fruta</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Verdura</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Cereales</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Leguminosas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Lácteos</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Proteínas</th>
      <th style={{color: "#004085", textAlign: "center"}} scope="col">Grasas</th>
      
    </tr>
  </thead>
  <tbody style={{background: "#FFFFFF",}}>
    <tr>
      <th style={{color: "#004085", textAlign: "center"}} scope="row">{dataPlan.cena_fruta}</th>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cena_verdura}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cena_cereal}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cena_leguminosa}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cena_lacteos}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cena_proteina}</td>
      <td style={{color: "#004085", textAlign: "center"}}>{dataPlan.cena_grasa}</td>
    </tr>
  </tbody>
</table>
</div>
            {(dataPlan.menu === "" ? 
            <a> 
            </a>
             :
             <div style={{
               textAlign: "center",
               marginTop: 30,
             }}> 
             <a  href={dataPlan.menu} class="btn-home" target="_blank">Menú 
            </a>
            </div>)}


            <button class="btn " onClick={volver} type="button">Volver</button>


            
            
           
        </>
    )
}
