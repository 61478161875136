import React, {useState, useEffect} from 'react';
import '../styles/Form.css';
import toast, { Toaster } from 'react-hot-toast';
import { firebaseApp } from "../firebase/Firebase";
import firebase from "firebase/app";
import {isEmpty, map} from "lodash"
import imagen from "../assets/img/us.jpg";
import "firebase/firestore";
const db = firebase.firestore(firebaseApp);

const Recordatorios = ({setRecordatorio, setFlag, history}) => {


  const volver = () => {
    //setRecordatorio(false);
    //setFlag(false);
    history.push("/VO2_NUTRITION");
  }

    
      const [users, setUsers] = useState("");
      const [correoUser, setCorreoUser] = useState("");
      const [token, setToken] = useState();
      const [fecha, setFecha] = useState();
      const [citas, setCitas] = useState();
      const [formData, setFormData] = useState(defaultFormValue());
      const [datos, setDatos] = useState();
      const [bandera, setBandera] = useState(false);
      let dia = "";
      let mes = "";
      let año = "";
      let date = "";
      dia = formData.fecha.substring(8,10);
      mes = formData.fecha.substring(5,7);
      año = formData.fecha.substring(0,4);
      date = dia + "/" + mes + "/" + año;
      
      const onChange = (e) => { 
        setFormData({...formData, [e.target.name]: e.target.value});
    }
    console.log(formData);

    useEffect(() => {
      formData.email = formData.email.toLowerCase();
      db.collection("usuarios")
          .doc(`${formData.email}`)
          .get()
          .then((response) => {
          setToken(response.data());
          });

          formData.email = formData.email.toLowerCase();
          db.collection("citas")
          .doc(`${formData.email}`)
          .get()
          .then((response) => {
            setFecha(response.data());
          });

          const correos = [];
        db.collection("usuarios").orderBy("nombre", "asc")
            .get()
            .then((response) => {
                setUsers(response.docs[response.docs.length - 1]);
              response.forEach((doc) => {
                const correo = doc.data();
                correo.id = doc.id;
                correos.push(correo);
              });
              setCorreoUser(correos);
            });

            const cita = [];
     // db.collection("citas").orderBy("filtroFecha", "asc")
        db.collection("citas").orderBy("fecha", "asc")
            .get()
            .then((response) => {
                setUsers(response.docs[response.docs.length - 1]);
              response.forEach((doc) => {
                const datos = doc.data();
                datos.id = doc.id;
                cita.push(datos);
              });
              setCitas(cita);
            });

  }, [formData.email, bandera])

  const enviar2 = ()  => {
      insertDatabase();
      history.push("/VO2_NUTRITION");
      //sendMesaage2(token.token, token.nombre);
  }

    function insertDatabase(){
        db.collection('citas').doc(`${formData.email}`).set({
        fecha: date,
        hora: formData.hora,
        estado: formData.estado,
        nombre: token.nombre,
        filtroFecha: formData.fecha,
      });
       
  }


  

  const  enviar4 =  (nombre, fecha, hora)  => {
    db.collection("usuarios").where("nombre", "==", nombre)
                      .get()
                      .then(function(querySnapshot) {
                          querySnapshot.forEach(function(doc) {
                            const obtenToken = doc.data();
                            setDatos(obtenToken);
                            enviar6(obtenToken.token, nombre, fecha, hora);
                          })
                      })
                      .catch(function(error) {
                          console.log("Error getting documents: ", error);
                      });      
}

const enviar5 = (nombre, fecha, hora)  => {
  db.collection("usuarios").where("nombre", "==", nombre)
  .get()
  .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        const obtenToken = doc.data();
        setDatos(obtenToken);
        console.log(datos);
        db.collection('citas').doc(`${obtenToken.email}`).update({
         fecha: "",
         hora: "",
         estado: "",
       });
       eliminarnotificacion();
       setBandera(!bandera);
      })
        
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });     
}

   const enviar = ()  => {
      sendMesaage(token.token, token.nombre, fecha.fecha, fecha.hora);
  }

  const enviar3 = (token, nombre, fecha, hora)  => {
    sendMesaage(token, nombre, fecha, hora);
}

const enviar6 = (token, nombre, fecha, hora)  => {
  sendMesaage3(token, nombre, fecha, hora);
}



const sendMesaage3 = (token, nombre, fecha, hora ) => {
  console.log(token, nombre);
  fetch('https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sound: "default",
      to: token,
      title: 'Nutri Gio:',
          body:  `${nombre}, recuerda que tu cita es el ${fecha} a las ${hora}`,
      data: { data: 'Vamos..' },
      _displayInForeground: true,
    }),
  });
  recordarnotificacion();
  // setRecordatorio(false);
  //   setFlag(false);
}




  const sendMesaage = (token, nombre, fecha, hora ) => {
    console.log(token, nombre);
    fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sound: "default",
        to: token,
        title: 'Nutri Gio:',
            body:  `${nombre}, recuerda que tu cita es el ${fecha} a las ${hora}`,
        data: { data: 'Vamos..' },
        _displayInForeground: true,
      }),
    });
    setRecordatorio(false);
    setFlag(false);
  }

  const sendMesaage2 = (token, nombre) => {
    console.log(token, nombre);
    fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sound: "default",
        to: token,
        title: 'Nutri Gio:',
            body:  `${nombre}, Se ha agendado tu proxima cita`,
        data: { data: 'Vamos..' },
        _displayInForeground: true,
      }),
    });
    setRecordatorio(false);
    setFlag(false);
  }

  const notification = () =>{
    toast.custom((t) => (
      <>
      {map(citas).map((item, index) => (
        <>
          {item.estado == "confirmada" ? <> 
          <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5" style={{textAlign: "center"}}>
                <img style={{width: "50px" , height: "50px"}}
                  className="h-10 w-10 rounded-full"
                  src={item.foto ? `${item.foto}` : imagen }
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1" style={{textAlign: "center"}}>
                <p className="text-sm font-medium text-gray-900">
                {item.nombre}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                Hola Gio! Confirmo mi cita del dia <br/> {item.fecha} a las {item.hora} 😁 
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
            style={{backgroundColor: "#f2a7bb", color: "White" }}
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Cerrar
            </button>
          </div>
        </div>
          </>
          
          : <></> }
        
</>
    ) )}
        {map(citas).map((item, index) => (
        <>
          {item.estado == "cancelada" ? <> 
          <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5" style={{textAlign: "center"}}>
                <img style={{width: "50px" , height: "50px"}}
                  className="h-10 w-10 rounded-full"
                  src={item.foto ? `${item.foto}` : imagen }
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1" style={{textAlign: "center"}}>
                <p className="text-sm font-medium text-gray-900">
                {item.nombre}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                Hola Gio! lo siento pero no podre asistir a mi cita del dia <br/> {item.fecha} a las {item.hora} ☹ 
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
            style={{backgroundColor: "#f2a7bb", color: "White" }}
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Cerrar
            </button>
          </div>
        </div>
          </>
          
          : <></> }
        
</>
    ) )}
        </>))
}


const recordarnotificacion= () => {
  toast.success('Notificacion enviada');
}

const eliminarnotificacion= () => {
  toast.success('Cita Eliminada');
  setBandera(!bandera);
}


    return (
      <>
      <div class="registro">
      <div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item" style={{textAlign: "center"}}>
      <Toaster
  position="bottom-right"
  reverseOrder={false}
  toastOptions={{
    className: '',
    duration: 1000,}}
/>
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="btn2" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
       Próxima cita
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
      <form>
                <div class="col col-md col-lg">
                <h1>Próxima cita</h1>
              <div style={{textAlign: "center"}}>
                  <input style={{width: "51%"}}  type="date" onChange={onChange} name="fecha" required/>
                  <input  style={{marginTop: 50, width: "51%", marginBottom: 50}} type="time" onChange={onChange} name="hora" required/>
                  <select style={{width: "100%", height:"100%", fontSize: 16}} class="form-select form-select-sm" aria-label=".form-select-sm example" name="email"  onChange={onChange}  required>
  <option selected disabled value="seleccion">Seleccionar paciente</option>

  {map(correoUser).map((item, index) => (
    <option value={item.email}>{item.nombre}</option>
                         ) )}
  
  
</select>
                  </div>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn  me-md-2" onClick={enviar2} type="submit">Agendar cita</button>
        <button class="btn" type="button" onClick={volver}>Volver</button>
        </div>
                  
    
    </div>
    
      </form>
      </div>
    </div>
  </div>
  <div class="accordion-item" style={{textAlign: "center"}}>
    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
      <button class="btn2" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
      Citas confirmadas
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
      <div class="accordion-body">
      <h1>Citas confirmadas</h1>
              <div style={{textAlign: "center"}}>
                  <table class="table table-responsive table-striped"  style={{fontSize: 15}}>
            <thead  style={{background: "#78bf8a", fontWeight: "bold"}}>
                <tr style={{color: "#fff"}}>
                <th style={{color: "#fff"}} scope="col">Paciente</th>
                <th style={{color: "#fff"}} scope="col">Fecha</th>
                <th style={{color: "#fff"}} scope="col">Hora</th>

                </tr>
            </thead>
                <tbody>
                    {map(citas).map((item, index) => (
                        <tr key={index}>
                          {item.estado == "confirmada" ? <> <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.nombre}</th>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.fecha}</th>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.hora}</th></>
                          : <></> }
                        
               </tr>
                    ) )}

                </tbody>
        </table>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn" type="button" onClick={volver}>Volver</button>
                  </div>
                  </div>
      </div>
    </div>
  </div>
  <div class="accordion-item" style={{textAlign: "center"}}>
    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
      <button class="btn2" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      Citas canceladas
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
      <div class="accordion-body">
      <h1>Citas canceladas</h1>
              <div style={{textAlign: "center"}}>
                  <table class="table table-responsive table-striped"  style={{fontSize: 15}}>
            <thead  style={{background: "#78bf8a", fontWeight: "bold"}}>
                <tr style={{color: "#fff"}}>
                <th style={{color: "#fff"}} scope="col">Paciente</th>
                <th style={{color: "#fff"}} scope="col">Fecha</th>
                <th style={{color: "#fff"}} scope="col">Hora</th>

                </tr>
            </thead>
                <tbody>
                    {map(citas).map((item, index) => (
                        <tr key={index}>
                          {item.estado == "cancelada" ? <> <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.nombre}</th>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.fecha}</th>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.hora}</th> </>
                          : <></> }
                        
               </tr>
                    ) )}

                </tbody>
        </table>
                  </div>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn" type="button" onClick={volver}>Volver</button>
                  </div>
      </div>
    </div>
  </div>
  <div class="accordion-item" style={{textAlign: "center"}}>
    <h2 class="accordion-header" id="panelsStayOpen-headingFour">
      <button class="btn2" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
      Citas pendientes
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
      <div class="accordion-body">
      <h1>Citas pendientes</h1>
              <div style={{textAlign: "center"}}>
                  <table class="table table-responsive table-striped"  style={{fontSize: 15}}>
            <thead  style={{background: "#78bf8a", fontWeight: "bold"}}>
                <tr style={{color: "#fff"}}>
                <th style={{color: "#fff"}} scope="col">Paciente</th>
                <th style={{color: "#fff"}} scope="col">Fecha</th>
                <th style={{color: "#fff"}} scope="col">Recordar</th>
                <th style={{color: "#fff"}} scope="col">Eliminar</th>


                </tr>
            </thead>
                <tbody>
                    {map(citas).map((item, index) => (
                        <tr key={index}>
                          {item.estado == "pendiente" && item.fecha != "" & item.hora != "" ? <> <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.nombre}</th>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.fecha}</th>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row"><button class="btn" type="button" onClick={()=> enviar4(item.nombre, item.fecha, item.hora)}>Recordar</button></th> 
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row"><button class="btn" type="button" onClick={()=> enviar5(item.nombre, item.fecha, item.hora)}>Eliminar</button></th> </> 
                          : <></> }
                        
               </tr>
                    ) )}

                </tbody>
        </table>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn" type="button" onClick={volver}>Volver</button>
                  </div>
                  </div>
      </div>
    </div>
  </div>

  <div class="accordion-item" style={{textAlign: "center"}}>
    <h2 class="accordion-header" id="panelsStayOpen-headingFour">
      <button class="btn2" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
      Mis pacientes
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
      <div class="accordion-body">
      <h1>Mis pacientes</h1>
              <div style={{textAlign: "center"}}>
                  <table class="table table-responsive table-striped"  style={{fontSize: 15}}>
            <thead  style={{background: "#78bf8a", fontWeight: "bold"}}>
                <tr style={{color: "#fff"}}>
                <th style={{color: "#fff"}} scope="col">Paciente</th>
                <th style={{color: "#fff"}} scope="col">Correo</th>
                </tr>
            </thead>
                <tbody>
                    {map(correoUser).map((item, index) => (
                        <tr key={index}>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.nombre}</th>
                          <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.email}</th>
                        
               </tr>
                    ) )}

                </tbody>
        </table>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn" type="button" onClick={volver}>Volver</button>
                  </div>
                  </div>
      </div>
    </div>
  </div>


  </div>
      
      </div>
        </>
    )
}

export default Recordatorios;

function defaultFormValue(){
    return{
        email: " ",
        fecha: "",
        hora: "",
        estado: "pendiente",
    }
}
   /*
   <button class="btn" type="button" onClick={notification}>Ver Notificaciones</button>
    */