import React from 'react';
import '../styles/sobremi.css';

export const SobreMi = () => {
    return (
        <>
            <section class="home-sobremi seccion-sobremi">

<div class="contenido-sobremi">
    <h3 class="hola" style={{fontSize: 30}}>¡Hola!</h3>
    <h3 class="nombre-sobremi">Yo Soy <span style={{fontSize: 58}}>Giovanna</span></h3>
    <p></p>
</div>

</section>
        </>
    )
}
