import React, { useState, useEffect } from 'react';
import '../styles/Login.css';
import vo2 from "../assets/img/vo2.png"
import * as firebase from "firebase";
import 'firebase/auth';
import { firebaseApp } from "../firebase/Firebase";
import "firebase/firestore";
import { UserLogged } from './UserLogged';
const db = firebase.firestore(firebaseApp);


export const Login = ({history, setIsAuthenticated, isAuthenticated}) => {
    const user =  firebase.auth().currentUser;
    const [formData, setFormData] = useState(datos());
    const [loading, setLoading] = useState(false);
    const [flagLogin, setFlagLogin] = useState(false)

    console.log(user);
   
    useEffect(() => {
          firebase.auth().onAuthStateChanged(user => {
            if (user != null){
                setFlagLogin(true);
            }
        });
    }, [flagLogin]);
    
    const onChange = (e) => { 
        setFormData({...formData, [e.target.name]: e.target.value});
    }

  
    const login = () =>{
        setLoading(true);
                    firebase
                    .auth()
                    .signInWithEmailAndPassword(formData.email, formData.psw)
                    .then(response =>{
                        setLoading(false);
                        setFlagLogin(true);
                        alert("Cuenta encontrada");
                    })
                    .catch(() =>{
                        setLoading(false);
                        alert("Cuenta no registrada");
                    }); 
     };

     

    return (
        <>
        <div className="login">        {(flagLogin == false ?
            <div class="her2">
            <div class="form-box2">
               
               <div class="imagen-centro">
               <img src={vo2} alt=""/>
               </div>
                    
                
                <form action="" id="login" class="input-group2">
                    <input type="text" class="input-field2" onChange={onChange} placeholder="Correo electrónico" name="email" required/>
                    <input type="password" class="input-field2" onChange={onChange} name="psw" placeholder="Contraseña" required/>
                    <br/>
                    <br/>
                    <input type="button" onClick={login} value="Iniciar" class="submit-btn2"/>
                    <br/>
                    {loading && <div style={{color: "#f2a7bb"}} class="spinner-border" role="status">
     
    </div> }
                
                </form>
    
    
            </div>
    
        </div> 
        : <UserLogged history={history} setFlagLogin={setFlagLogin} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />)}
            
            </div>
        </>
    )
}

function datos() {
    return{
        email: "",
        psw: "",
    }
    
}