import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import PantallaInicio from '../inicio/PantallaInicio';
import DashboardRoutes from './DashboardRoutes';

export const AppRouter = () => {
    return (
        <Router>
        <div>
          <Switch>
           <Route exact path="/inicio" component={PantallaInicio}/>
           <Route  path="/" component={DashboardRoutes}/>
          </Switch>
        </div>
      </Router>
    );
}
