import React from 'react';
import Pie from '../inicio/Pie';
import { Navbar } from '../navbar/Navbar';
import { Login } from './Login';

export const PaginaLogin = ({history, setIsAuthenticated, isAuthenticated}) => {
    console.log("AUTENTICACION"+isAuthenticated);
    return (
        <>
            <Login history={history} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />
        </>
    )
}
