import React, {useState, useEffect} from 'react';
import { firebaseApp } from "../firebase/Firebase";
import '../styles/Form.css';
import firebase from "firebase/app";
import toast, { Toaster } from 'react-hot-toast';
import "firebase/firestore";
import {isEmpty, map} from "lodash";
const db = firebase.firestore(firebaseApp);

const DietForm = ({setPlan, setFlag, user, history}) => {

  const [token, setToken] = useState();
  const [users, setUsers] = useState("");
  const [correoUser, setCorreoUser] = useState("");
  const [formData, setFormData] = useState(defaultFormValue());
  const [archivoUrl, setArchivoUrl] = useState("");
  const onChange = (e) => { 
    setFormData({...formData, [e.target.name]: e.target.value});
  }
  const volver = () => {
    //setPlan(false);
    //setFlag(false);
    history.push("/VO2_NUTRITION");
  }

  useEffect(() => {
    formData.email = formData.email.toLowerCase();
    db.collection("usuarios")
        .doc(`${formData.email}`)
        .get()
        .then((response) => {
        setToken(response.data());
        });
        const correos = [];
        db.collection("usuarios").orderBy("nombre", "asc")
            .get()
            .then((response) => {
                setUsers(response.docs[response.docs.length - 1]);
              response.forEach((doc) => {
                const correo = doc.data();
                correo.id = doc.id;
                correos.push(correo);
              });
              setCorreoUser(correos);
            });
}, [formData.email])
  
  console.log(formData);

  const subirarchivos = async (e) => {

    const archivo = e.target.files[0];
    const storageref = firebase.storage().ref();
    const archivoPath = storageref.child(`menu/${formData.email}/${archivo.name}`);
    await archivoPath.put(archivo);
    const enlaceurl = await archivoPath.getDownloadURL();
    setArchivoUrl(enlaceurl);
    Listoparaenviar();
    }
 
    const enviar = ()  => {
      if (isEmpty(formData.email)){
        alert("falta info")
      } else{
        insertDatabase();
        history.push("/VO2_NUTRITION");
        //sendMesaage(token.token, token.nombre);
      }
    }
  
  function insertDatabase(){
    db.collection('plan').doc(`${formData.email}`).set({
        desayuno_fruta: formData.desayuno_fruta,
        desayuno_verdura: formData.desayuno_verdura,
        desayuno_cereal: formData.desayuno_cereal,
        desayuno_lacteos: formData.desayuno_lacteo,
        desayuno_leguminosa: formData.desayuno_leguminosa,
        desayuno_proteina: formData.desayuno_proteina,
        desayuno_grasa: formData.desayuno_grasa,
        cm_fruta: formData.cm_fruta,
        cm_verdura: formData.cm_verdura,
        cm_cereal: formData.cm_cereal,
        cm_lacteos: formData.cm_lacteo,
        cm_leguminosa: formData.cm_leguminosa,
        cm_proteina: formData.cm_proteina,
        cm_grasa: formData.cm_grasa,
        comida_fruta: formData.comida_fruta,
        comida_verdura: formData.comida_verdura,
        comida_cereal: formData.comida_cereal,
        comida_lacteos: formData.comida_lacteo,
        comida_leguminosa: formData.comida_leguminosa,
        comida_proteina: formData.comida_proteina,
        comida_grasa: formData.comida_grasa,
        cv_fruta: formData.cv_fruta,
        cv_verdura: formData.cv_verdura,
        cv_cereal: formData.cv_cereal,
        cv_lacteos: formData.cv_lacteo,
        cv_leguminosa: formData.cv_leguminosa,
        cv_proteina: formData.cv_proteina,
        cv_grasa: formData.cv_grasa,
        cena_fruta: formData.cena_fruta,
        cena_verdura: formData.cena_verdura,
        cena_cereal: formData.cena_cereal,
        cena_lacteos: formData.cena_lacteo,
        cena_leguminosa: formData.cena_leguminosa,
        cena_proteina: formData.cena_proteina,
        cena_grasa: formData.cena_grasa,
        menu: archivoUrl,
    });
}

const sendMesaage = (token, nombre) => {
  console.log(token, nombre);
  fetch('https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sound: "default",
      to: token,
      title: 'Plan Nutricional Actualizado',
      body:  `${nombre}, ya tienes tu nuevo plan de alimentación`,
      data: { data: 'Vamos..' },
      _displayInForeground: true,
    }),
  });
  planNotification();
  setPlan(false);
  setFlag(false);
}

const planNotification= () => {
  toast.success('Plan nutricional agregado');
}
const Listoparaenviar= () => {
  toast.success('Listo para enviar');
}

    return (
        <>
        <Toaster
  position="bottom-right"
  reverseOrder={false}
  toastOptions={{
    className: '',
    duration: 1000,}}
/>
        <form>
         <div class="registro">
        <h1>Registro Plan nutricional</h1>
            

<div class="container">
    <h2 class="resultados">Tiempos de comida: Desayuno</h2>
    <div class="row">
      <div class="col col-md col-lg">
        <label>Frutas</label>
        <input type="text" placeholder="frutas" onChange={onChange} name="desayuno_fruta" required/>
      </div>
      <div class="col col-md col-lg">
        <label>Verduras:</label>
        <input type="text" placeholder="verduras" onChange={onChange} name="desayuno_verdura" required/>
      </div>
      <div class="col col-md col-lg">
        <label>Cereales:</label>
        <input type="text" placeholder="cereales" onChange={onChange} name="desayuno_cereal"/>
      </div>
      <div class="col col-md col-lg">
         <label>Leguminosas:</label>
        <input type="text" placeholder="leguminosas" onChange={onChange} name="desayuno_leguminosa"/>
      </div>
    </div>

    <div class="row">
        <div class="col col-md col-lg">
           <label>Lácteos:</label>
          <input type="text" placeholder="lácteos" onChange={onChange} name="desayuno_lacteo"/>
        </div>
        <div class="col col-md col-lg">
           <label>Proteínas:</label>
          <input type="text" placeholder="proteínas" onChange={onChange} name="desayuno_proteina"/>
        </div>
        <div class="col col-md col-lg">
           <label>Grasas:</label>
          <input type="text" placeholder="grasas" onChange={onChange} name="desayuno_grasa"/>
        </div>

      </div>


</div>

<hr/>

<div class="container">
    <h2 class="resultados">Tiempos de comida: MM Colación</h2>
    <div class="row">
      <div class="col col-md col-lg">
        <label>Frutas</label>
        <input type="text" placeholder="frutas" onChange={onChange} name="cm_fruta"/>
      </div>
      <div class="col col-md col-lg">
        <label>Verduras:</label>
        <input type="text" placeholder="verduras" onChange={onChange} name="cm_verdura"/>
      </div>
      <div class="col col-md col-lg">
        <label>Cereales:</label>
        <input type="text" placeholder="cereales" onChange={onChange} name="cm_cereal"/>
      </div>
      <div class="col col-md col-lg">
         <label>Leguminosas:</label>
        <input type="text" placeholder="leguminosas" onChange={onChange} name="cm_leguminosa"/>
      </div>
    </div>

    <div class="row">
        <div class="col col-md col-lg">
           <label>Lácteos:</label>
          <input type="text" placeholder="lácteos" onChange={onChange} name="cm_lacteo"/>
        </div>
        <div class="col col-md col-lg">
           <label>Proteínas:</label>
          <input type="text" placeholder="proteínas" onChange={onChange} name="cm_proteina"/>
        </div>
        <div class="col col-md col-lg">
           <label>Grasas:</label>
          <input type="text" placeholder="grasas" onChange={onChange} name="cm_grasa"/>
        </div>

      </div>


</div>

<hr/>

<div class="container">
    <h2 class="resultados">Tiempos de comida: Comida</h2>
    <div class="row">
      <div class="col col-md col-lg">
        <label>Frutas</label>
        <input type="text" placeholder="frutas" onChange={onChange} name="comida_fruta"/>
      </div>
      <div class="col col-md col-lg">
        <label>Verduras:</label>
        <input type="text" placeholder="verduras" onChange={onChange} name="comida_verdura"/>
      </div>
      <div class="col col-md col-lg">
        <label>Cereales:</label>
        <input type="text" placeholder="cereales" onChange={onChange} name="comida_cereal"/>
      </div>
      <div class="col col-md col-lg">
         <label>Leguminosas:</label>
        <input type="text" placeholder="leguminosas" onChange={onChange} name="comida_leguminosa"/>
      </div>
    </div>

    <div class="row">
        <div class="col col-md col-lg">
           <label>Lácteos:</label>
          <input type="text" placeholder="lácteos" onChange={onChange} name="comida_lacteo"/>
        </div>
        <div class="col col-md col-lg">
           <label>Proteínas:</label>
          <input type="text" placeholder="proteínas" onChange={onChange} name="comida_proteina"/>
        </div>
        <div class="col col-md col-lg">
           <label>Grasas:</label>
          <input type="text" placeholder="grasas" onChange={onChange} name="comida_grasa"/>
        </div>

      </div>


</div>

<hr/>

<div class="container">
    <h2 class="resultados">Tiempos de comida: MT Colación</h2>
    <div class="row">
      <div class="col col-md col-lg">
        <label>Frutas</label>
        <input type="text" placeholder="frutas" onChange={onChange} name="cv_fruta"/>
      </div>
      <div class="col col-md col-lg">
        <label>Verduras:</label>
        <input type="text" placeholder="verduras" onChange={onChange} name="cv_verdura"/>
      </div>
      <div class="col col-md col-lg">
        <label>Cereales:</label>
        <input type="text" placeholder="cereales" onChange={onChange} name="cv_cereal"/>
      </div>
      <div class="col col-md col-lg">
         <label>Leguminosas:</label>
        <input type="text" placeholder="leguminosas" onChange={onChange} name="cv_leguminosa"/>
      </div>
    </div>

    <div class="row">
        <div class="col col-md col-lg">
           <label>Lácteos:</label>
          <input type="text" placeholder="lácteos" onChange={onChange} name="cv_lacteo"/>
        </div>
        <div class="col col-md col-lg">
           <label>Proteínas:</label>
          <input type="text" placeholder="proteínas" onChange={onChange} name="cv_proteina"/>
        </div>
        <div class="col col-md col-lg">
           <label>Grasas:</label>
          <input type="text" placeholder="grasas" onChange={onChange} name="cv_grasa"/>
        </div>

      </div>


</div>

<hr/>

<div class="container">
    <h2 class="resultados">Tiempos de comida: Cena</h2>
    <div class="row">
      <div class="col col-md col-lg">
        <label>Frutas</label>
        <input type="text" placeholder="frutas" onChange={onChange} name="cena_fruta"/>
      </div>
      <div class="col col-md col-lg">
        <label>Verduras:</label>
        <input type="text" placeholder="verduras" onChange={onChange} name="cena_verdura"/>
      </div>
      <div class="col col-md col-lg">
        <label>Cereales:</label>
        <input type="text" placeholder="cereales" onChange={onChange} name="cena_cereal"/>
      </div>
      <div class="col col-md col-lg">
         <label>Leguminosas:</label>
        <input type="text" placeholder="leguminosas" onChange={onChange} name="cena_leguminosa"/>
      </div>
    </div>

    <div class="row">
        <div class="col col-md col-lg">
           <label>Lácteos:</label>
          <input type="text" placeholder="lácteos" onChange={onChange} name="cena_lacteo"/>
        </div>
        <div class="col col-md col-lg">
           <label>Proteínas:</label>
          <input type="text" placeholder="proteínas" onChange={onChange} name="cena_proteina"/>
        </div>
        <div class="col col-md col-lg">
           <label>Grasas:</label>
          <input type="text" placeholder="grasas" onChange={onChange} name="cena_grasa"/>
        </div>
        <div class="col col-md col-lg">
        <label >Paciente:</label>
        <select style={{width: "100%", height:"100%", fontSize: 16}} class="form-select form-select-sm" aria-label=".form-select-sm example" name="email"  onChange={onChange}  required>
  <option selected disabled value="seleccion">Seleccionar paciente</option>

  {map(correoUser).map((item, index) => (
    <option value={item.email}>{item.nombre}</option>
                         ) )}
  
  
</select>
        </div>
        <input type="file" name="evidencia" onChange={subirarchivos} class="form-control-file btn-home" id="exampleFormControlFile1"/>
      </div>
</div>

<hr/>
</div>
<div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn  me-md-2" onClick={enviar}  type="submit">Registrar</button>
        <button class="btn " onClick={volver} type="button">Cancelar</button>
      </div>
      </form>
        </>
    )
}

export default DietForm;


function defaultFormValue(){
  return{
      email: " ",
      desayuno_fruta: "",
      desayuno_verdura: "",
      desayuno_cereal: "",
      desayuno_leguminosa: "",
      desayuno_lacteo: "",
      desayuno_proteina: "",
      desayuno_grasa: "",
      cm_fruta: "",
      cm_verdura: "",
      cm_cereal: "",
      cm_leguminosa: "",
      cm_lacteo: "",
      cm_proteina: "",
      cm_grasa: "",
      comida_fruta: "",
      comida_verdura: "",
      comida_cereal: "",
      comida_leguminosa: "",
      comida_lacteo: "",
      comida_proteina: "",
      comida_grasa: "",
      cv_fruta: "",
      cv_verdura: "",
      cv_cereal: "",
      cv_leguminosa: "",
      cv_lacteo: "",
      cv_proteina: "",
      cv_grasa: "",
      cena_fruta: "",
      cena_verdura: "",
      cena_cereal: "",
      cena_leguminosa: "",
      cena_lacteo: "",
      cena_proteina: "",
      cena_grasa: "",  
      menu: "",
  }
}