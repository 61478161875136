import React from 'react'
import {Educacion} from "../sobreMi/Educacion"

export default function Fuentes() {
  return (
      <div>
         <h1 style={{paddingTop: 100, paddingBottom: 50}} class="encabezado">Referencias</h1>
         <h2 style={{textAlign:"center", marginLeft: 50, marginRight: 50, paddingBottom: 50}}>
         La aplicación VO2 NUTRITION es administrada por la Maestra en nutrición deportiva Giovanna Rangel Murillo, 
         a continuación se presentan las principales referencias que fueron consultadas y tomadas para el 
         desarrollo de esta aplicación, además de proporcionar la educación, logros y experiencias de la Maestra Giovanna Rangel Murillo.
         </h2>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         Ana Bertha Pérez, Berenice Palacios, Ana Laura Castro, Isabel Flores. (2014). <br/> Sistema Mexicano de alimentos equivalentes. México, D.F. : Ogali.
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         L. Kathleen Mahan, S. Escott-Stump. (2017). En Krause: Dietoterapia(1376). <br/> España : Elsevier-Masson.
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         Nicole Cormier. (2011). En The everything guide to nutrition (307). <br/> United States of America: Adams media.
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         Dr. Roger Pamplona. (2015). En El poder medicinal de los alimentos (383). <br/> España: Safeliz .
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         Olga Lopez Torres . (2016). En Nutricion para deportistas(160). <br/> Madrid, España : Libsa.
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         Salvador Badui Dergal. (2006). -. En Química de los alimentos (716). <br/> México : Pearson educación .
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         Sylvia Escott-Stump. (2012). En Nutrición, diagnóstico y tratamiento, 7ma edición (1020). <br/> Estados unidos: Lippincott Williams & Wilkins.
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         José Angel Ledesma, Adolfo Chávez, Fernando Pérez-Gil, <br/> Eduardo Mendoza, Concepción Calvo. (2010). <br/> En Composición de los alimentos, valor nutritivo de los alimentos de mayor consumo(365). <br/> México: Mc Gray Hill .
         </p>
         <p style={{marginLeft: 80, marginRight: 80, paddingBottom: 50}}>
         
         </p>
    <Educacion/>
    </div>
  )
}
