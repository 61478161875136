import React from 'react';
import '../styles/contInicio.css';
import '../styles/servicios.css';

export const Servicios = () => {
    return (
        <>
           <section class="home-servi seccion-servi">

<div class="contenido-servi">
    <h3 style={{fontWeight: "bolder", fontSize: 30}} class="hola-servi">Servicios</h3>
    <h3 class="nombre-servi">Conoce <span>Nuestros catálogo</span></h3>
    <p></p>
</div>

</section>

<section class="servicio">

<h1 class="encabezado">Servicios</h1>
<br/>
<br/>
<br/>
<div class="box-container-sobremi">

    <div style={{height: "320px" }} class="box-sobremi">
        <span class="numero-servicio">01</span>
        <h3>Consulta Presencial</h3>
        <p>Todas nuestras consultas y planes de alimentación son totalmente personalizadas, se llena un historial clinico, 
     se toman peso, medidas y % de composición corporal.</p>
        <a class="btn-home" data-bs-toggle="modal" data-bs-target="#presencialModal">Conoce más</a>
    </div>

    <div style={{height: "320px" }} class="box-sobremi">
        <span class="numero-servicio">02</span>
        <h3>Consulta en linea</h3>
        <p>Todas nuestras consultas y planes de alimentación son totalmente personalizadas, se llena un historial 
           clinico en linea y en consulta se reforza con preguntas más especificas.</p>
        <a class="btn-home" data-bs-toggle="modal" data-bs-target="#onlineModal">Conoce más</a>
    </div>

    <div style={{height: "320px" }} class="box-sobremi">
        <span class="numero-servicio">03</span>
        <h3>Nutrición para tu empresa</h3>
        <p>Hoy en día sabemos que nuestros tiempos son mas complicados por diversas ocupaciones, es por eso que me 
           di a la tarea de crear un programa de “nutrición a tu empresa”.</p>
        <a class="btn-home" data-bs-toggle="modal" data-bs-target="#empresaModal">Conoce más</a>
    </div>
    
</div>

</section>

<div class="modal fade" id="empresaModal" tabindex="-1" aria-labelledby="empresaModalLabel" aria-hidden="true">
       <div class="modal-dialog">
         <div class="modal-content">
           <div class="modal-header">
             <h5 style={{fontSize: 25}} class="modal-title" id="empresaModalLabel">Nutrición para tu empresa:</h5>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div style={{fontSize: 18}} class="modal-body">
           Hoy en día sabemos que nuestros tiempos son mas complicados por diversas ocupaciones, es por eso que me 
           di a la tarea de crear un programa de “nutrición a tu empresa” el cual es llevar la consulta a las 
           instalaciones para que los trabajadores cuenten con las herramientas para crear un cambio de hábitos y 
           mejorar su estado de salud, un empleado sano es mas productivo y menos costoso para la empresa.
           </div>
           <div class="modal-footer">
             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
           </div>
         </div>
       </div>
     </div>
     <div class="modal fade" id="presencialModal" tabindex="-1" aria-labelledby="presencialModalLabel" aria-hidden="true">
       <div class="modal-dialog">
         <div class="modal-content">
           <div class="modal-header">
             <h5 style={{fontSize: 25}} class="modal-title" id="presencialModalLabel">Consulta presencial</h5>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div style={{fontSize: 18}} class="modal-body">
           Todas nuestras consultas y planes de alimentación son totalmente personalizadas, se llena un historial clinico, 
     se toman peso, medidas y % de composición corporal, con base a eso, estilo de vida y objetivos se realiza un 
     plan de alimentación personalizado que se entrega en el momento, las connsultas por lo general son cada dos 
     semanas, sin embargo puede variar en cada persona. 
           </div>
           <div class="modal-footer">
             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
             
           </div>
         </div>
       </div>
     </div>
     
     <div class="modal fade" id="onlineModal" tabindex="-1" aria-labelledby="onlineModalLabel" aria-hidden="true">
       <div class="modal-dialog">
         <div class="modal-content">
           <div class="modal-header">
             <h5 style={{fontSize: 25}} class="modal-title" id="onlineModalLabel">Consulta en linea</h5>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div style={{fontSize: 18}} class="modal-body">
           Todas nuestras consultas y planes de alimentación son totalmente personalizadas, se llena un historial 
           clinico en linea y en consulta se reforza con preguntas más especificas, se hace unavideollamada por la 
           plataforma que mejor se le acomode al paciente, el se pesa y en la videollamada se le enseña a medirse, 
           su resultados y plan de alimentación se envian digitalmente por whats app o correo electronico, las 
           connsultas por lo general son cada dos semanas, sin embargo puede variar en cada persona.  
           </div>
           <div class="modal-footer">
             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
           </div>
         </div>
       </div>
     </div>
        </>
    )
}
