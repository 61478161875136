import React from 'react';
import '../styles/App.css';
import vo2 from "../assets/img/vo2.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink } from 'react-router-dom'

export const Navbar = ({flagi, setFlagi}) => {
    return (
        <>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
  <a class="navbar-brand">
    <Link 
                className="navbar-brand" 
                to="/"
            >
              <img style={{width: 150,}} src={vo2} />
              </Link>
              </a>

     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
         <NavLink 
                        activeClassName="active"
                        className="nav-item nav-link" 
                        exact
                        to="/inicio"
                    >
                         <a style={{fontSize: 20, marginRight:15, marginLeft:15}}>Inicio</a>
                    </NavLink>
        </li>
        <li class="nav-item">
        <NavLink 
                        activeClassName="active"
                        className="nav-item nav-link" 
                        exact
                        to="/Sobre_mi"
                    >
                        <a style={{fontSize: 20, marginRight:15, marginLeft:15}}>Sobre mí</a>
                    </NavLink>
        </li>
        <li class="nav-item">
        <NavLink 
                        activeClassName="active"
                        className="nav-item nav-link" 
                        exact
                        to="/Servicios"
                    >
                        <a style={{fontSize: 20, marginRight:15, marginLeft:15}}>Servicios</a>
                    </NavLink>
        </li>
        <li class="nav-item">
        <NavLink 
                        activeClassName="active"
                        className="nav-item nav-link" 
                        exact
                        to="/Mis_Atletas"
                    >
                        <a style={{fontSize: 20, marginRight:15, marginLeft:15}}>Mis Atletas</a>
                    </NavLink>
        </li>
      </ul>
      <NavLink style={{color: "#f2a7bb"}}
                        activeClassName="active"
                        className="nav-item nav-link" 
                        exact
                        to="/VO2_NUTRITION"
                    >

             <a style={{fontSize: 20}}>VO2 NUTRITION APP</a>
                    </NavLink>
    </div>
  </div>
</nav>

        </>
    )
}

 /* 
 import React from 'react';
import '../contInicio.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink } from 'react-router-dom'

export const Navbar = ({flagi, setFlagi}) => {
    return (
        <> 
        <nav>
        <header>
        <Link 
                 
                to="/"
            >
              <a class="logo-inicio"><span>V02</span>NUTRITION</a>
              </Link>


<input type="checkbox" id="menu-bar-inicio"/>
<label for="menu-bar-inicio" class="fas fa-bars"></label>

<div class="navbar-inicio">
      <NavLink 
       activeClassName="active"
       exact
       to="/inicio">
      Inicio
      </NavLink>
      <NavLink 
       activeClassName="active"
       exact
       to="/Sobre_mi">
       Sobre mi 
      </NavLink>
      <NavLink 
       activeClassName="active"
       exact
       to="/Servicios">
       Servicios 
      </NavLink>
      <NavLink 
       activeClassName="active"
       exact
       to="/Mis_Atletas">
       Mis Atletas 
      </NavLink>
      <NavLink 
       activeClassName="active"
       exact
       to="/VO2_NUTRITION">
       VO2NUTRITION APP 
      </NavLink>
</div>

</header>
</nav>
        </>
    )
}
 */