import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link
  } from "react-router-dom";
import { Redirect, Switch } from 'react-router';
import {Navbar} from '../navbar/Navbar';
import PantallaInicio from '../inicio/PantallaInicio';
import { PaginaLogin } from '../login/PaginaLogin';
import { PantallaSobreMi } from '../sobreMi/PantallaSobreMi';
import { PantallaServicios } from '../servicios/PantallaServicios';
import { PantallaMisAtletas } from '../misAtletas/PantallaMisAtletas';
import Fuentes from '../fuentes/Fuentes';
import Recordatorios from '../formularios/Recordatorios';
import DietForm from '../formularios/DietForm';
import GainsForm from '../formularios/GainsForm';
import PrivateRoute from './PrivateRoute';


export default function DashboardRoutes() {
   const [isAuthenticated, setIsAuthenticated] = useState(false);

    return (
       <>
       <Navbar isAuthenticated={isAuthenticated} />
       <div>
           <Switch >
               <Route exact path="/inicio" component={PantallaInicio} />
               <Route exact path="/Sobre_mi" component={PantallaSobreMi} />
               <Route exact path="/Servicios" component={PantallaServicios} />
               <Route exact path="/Mis_Atletas" component={PantallaMisAtletas} />
               <Route exact path="/VO2_NUTRITION" render={props => (
                        <PaginaLogin {...props} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />
                    )} />
               <Route exact path="/Referencias" component={Fuentes} />

               <PrivateRoute 
                        exact 
                        path="/VO2_NUTRITION/Citas" 
                        component={Recordatorios} 
                        isAuthenticated={isAuthenticated} 
                    />
               <PrivateRoute 
                        exact 
                        path="/VO2_NUTRITION/Resultados" 
                        component={GainsForm} 
                        isAuthenticated={isAuthenticated} 
                    />
               <PrivateRoute 
                        exact 
                        path="/VO2_NUTRITION/Plan_Nutricional" 
                        component={DietForm} 
                        isAuthenticated={isAuthenticated} 
                    />
               <Redirect to="/inicio" />
           </Switch>
       </div>
       </>
    )
}
