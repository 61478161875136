import React from 'react';
import "../styles/contInicio.css";

export const Educacion = () => {
    return (
        <>
            <section class="educacion">
        <h1 class="encabezado">Educación y Experiencia</h1>
        <br/>
        <div class="hilera-sobremi">

            <div class="columna-experiencia">

                <h3 class="titulo-sobremi">DESARROLLO PROFESIONAL </h3>

                <div class="box-sobremi">
                   {/* <div class="año"> <i class="fas fa-calendar"></i>2020-2021</div> */}
                    <h3>Maestría en nutrición deportiva- UVM</h3>
                </div>

                <div class="box-sobremi">
                    {/* <div class="año"> <i class="fas fa-calendar"></i>2020-2021</div> */}
                    <h3>Actualización en nutrición en el paciente oncológico</h3>
                </div>

                <div class="box-sobremi">
                    {/* <div class="año"> <i class="fas fa-calendar"></i>2020-2021</div> */}
                    <h3>Certificación de antropometría ISAK level 1 </h3>
                </div>

                <div class="box-sobremi">
                    {/* <div class="año"> <i class="fas fa-calendar"></i>2020-2021</div> */}
                    <h3>Diplomado en masoterapia- UAG</h3>
                </div>

                <div class="box-sobremi">
                    {/* <div class="año"> <i class="fas fa-calendar"></i>2020-2021</div> */}
                    <h3>Diplomado en promotor de salud en diabetes- UAG</h3>
                </div>

                <div class="box-sobremi">
                    {/* <div class="año"> <i class="fas fa-calendar"></i>2020-2021</div> */}
                    <h3>Actualización en mesoterapia- fit weight Dr. Ramón Gutiérrez</h3>
                </div>

                <div class="box-sobremi">
                    {/* <div class="año"> <i class="fas fa-calendar"></i>2020-2021</div> */}
                    <h3>Licenciatura en Nutrición- UDG (titulada con cedula estatal y federal)</h3>
                </div>

            </div>


            <div class="columna-experiencia">

                <h3 class="titulo-sobremi">PONENCIAS Y DOCENCIAS</h3>

                <div class="box-sobremi">
                  
                    <h3>“Alimentación e hidratación en el ejercicio” selección de gimnasia rítmica del CODE.</h3>
                    
                </div>

                <div class="box-sobremi">
                 
                    <h3>“Abordaje nutricional en el running” – Universidad Continente Americano- Celaya, Gto.</h3>
                    
                </div>

                <div class="box-sobremi">
                    
                    <h3>“Nutrición para el desarrollo de masa muscular” - Universidad Continente Americano- Celaya, Gto.</h3>

                </div>

                <div class="box-sobremi">
                    
                    <h3>“Química y biología, bachillerato semi escolarizado - Instituto Zapopan.</h3>
                    
                </div>

                <div class="box-sobremi">
                    
                    <h3>VII Jornada de salud y nutrición; “gastroenterología en nutrición: Tx nutrición en pancreatitis” – Congreso.</h3>
                    
                </div>

                <br/>

                <h3 class="titulo-sobremi">EXPERIENCIAS </h3>

                <div class="box-sobremi">
                    
                    <h3>Consultoría en apertura de restaurantes y gerenciales.</h3>
                    
                </div>

                <div class="box-sobremi">
                    
                    <h3>Servicio de comedores industriales (distintivo H, NOM-251, Sistema de análisis de peligros y puntos críticos de control)</h3>
                    
                </div>

                <div class="box-sobremi">
                    
                    <h3>Nutrición clínica en hospitales; Hospital civil viejo e ISSSTE (practicas profesionales)</h3>
                    
                </div>

            </div>


        </div>
        <br/>
        <br/>
        <br/> 
        <br/>
        
    </section>
        </>
    )
}
