import React from 'react'
import Pie from '../inicio/Pie'
import { Servicios } from './Servicios'

export const PantallaServicios = ({history}) => {
    return (
        <>
        <Servicios history={history}/>
        <Pie history={history}/>
        </>
    )
}
