import React from 'react';
import Pie from '../inicio/Pie';
import { Educacion } from './Educacion';
import { Estadisticas } from './Estadisticas';
import { SobreMi } from './SobreMi';

export const PantallaSobreMi = ({history}) => {
    return (
        <>
        <SobreMi history={history}/>
        <Estadisticas history={history}/>
        <Educacion history={history}/>
        <Pie history={history}/>
        </>
    )
}
