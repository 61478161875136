import React, {useState, useEffect} from 'react';
import '../styles/Form.css';
import toast, { Toaster } from 'react-hot-toast';
import { firebaseApp } from "../firebase/Firebase";
import firebase from "firebase/app";
import {isEmpty, map} from "lodash"
import "firebase/firestore";
const db = firebase.firestore(firebaseApp);

const GainsForm = ({setRes, setFlag, history}) => {


  const volver = () => {
    //setRes(false);
    //setFlag(false);
    history.push("/VO2_NUTRITION");
  }

    const MESES = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const [users, setUsers] = useState("");
      const [correoUser, setCorreoUser] = useState("");
      const [token, setToken] = useState();
      const fecha = new Date();
      const mes = MESES[fecha.getMonth()];
      const ndia = fecha.getDate();
      const [cita, setCita] = useState("");
      const año = fecha.getFullYear();
      const finalfech = ndia + "/" + mes + "/" +año;
      const [formData, setFormData] = useState(defaultFormValue());
      
      const onChange = (e) => { 
        setFormData({...formData, [e.target.name]: e.target.value});
    }
    console.log(formData);

    useEffect(() => {
      formData.email = formData.email.toLowerCase();
      db.collection("usuarios")
          .doc(`${formData.email}`)
          .get()
          .then((response) => {
          setToken(response.data());
          });
          const correos = [];
        db.collection("usuarios").orderBy("nombre", "asc")
            .get()
            .then((response) => {
                setUsers(response.docs[response.docs.length - 1]);
              response.forEach((doc) => {
                const correo = doc.data();
                correo.id = doc.id;
                correos.push(correo);
              });
              setCorreoUser(correos);
            });


            db.collection("citas")
            .doc(`${formData.email}`)
            .get()
            .then((response) => {
            setCita(response.data());
            });

  }, [formData.email])

   const enviar = ()  => {
    if (isEmpty(formData.email && formData.gluteo && formData.grasa && formData.imc)){
      alert("Completa todo el formulario para continuar")
    } else{
      insertDatabase();
      //sendMesaage(token.token, token.nombre);
      gainsNotification();
      history.push("/VO2_NUTRITION");
    }
  }

    function insertDatabase(){
        db.collection('resultados_dia').doc(`${formData.email}`).set({
            abdomen:formData.abdomen,
            cadera:formData.cadera,
            cintura:formData.cintura,
            edadm:formData.edadm,
            gluteo:formData.gluteo,
            grasa:formData.grasa,
            imc:formData.imc,
            musculo: formData.musculo,
            pecho: formData.pecho,
            peso: formData.peso,
            pierna: formData.pierna,
            grasav: formData.grasav,
            edadmetabolica: formData.edadmetabolica,
        });
        db.collection('resultados').doc(`${formData.email}`).collection("resultados").doc(Date()).set({
            abdomen:formData.abdomen,
            cadera:formData.cadera,
            cintura:formData.cintura,
            edadm:formData.edadm,
            gluteo:formData.gluteo,
            grasa:formData.grasa,
            imc:formData.imc,
            musculo: formData.musculo,
            pecho: formData.pecho,
            peso: formData.peso,
            pierna: formData.pierna,
            createAt: fecha,
            fecha: finalfech,
            grasav: formData.grasav,
            edadmetabolica: formData.edadmetabolica,

        });     
  }

  const sendMesaage = (token, nombre) => {
    console.log(token, nombre);
    fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sound: "default",
        to: token,
        title: 'Resultados Actualizados',
            body:  `${nombre}, ya tienes tus nuevos resultados`,
            // badge: 1,
        data: { data: 'Vamos..' },
        _displayInForeground: true,
      }),
    });
    setRes(false);
    setFlag(false);
  }

  const gainsNotification= () => {
    toast.success('Resultados agregados');
  }

    return (
      <>
       <Toaster
  position="bottom-right"
  reverseOrder={false}
  toastOptions={{
    className: '',
    duration: 1000,}}
/>
      <div class="registro">
      <form>
      <div>
        <h1>Registro de Resultados</h1>
       
        
        <div class="container">
            <h2 class="resultados">Resultados</h2>
            <div class="row">
              
              <div class="col col-md col-lg">
                <label >Peso: </label>
                <input type="text" placeholder="Kg"  onChange={onChange} name="peso" required/>
                <div class="valid-feedback">
      Looks good!
    </div>
              </div>
            
            
              <div class="col col-md col-lg">
              <label >Grasa:</label>
                <input type="text" placeholder="% de grasa" onChange={onChange} name="grasa" required />
              </div>
              <div class="col col-md col-lg">
              <label >Agua:</label>
                <input type="text" placeholder="% de agua" onChange={onChange} name="edadm" required/>
              </div>
              <div class="col col-md col-lg">
              <label >Músculo:</label>
                <input type="text" placeholder="% de musculo" onChange={onChange} name="musculo" required/>
              </div>
            </div>

            <div class="row">
                <div class="col col-md col-lg">
            <label >Hueso:</label>
                  <input type="text" placeholder="Hueso (kg) " onChange={onChange} name="imc" required/>
                </div>
                <div class="col col-md col-lg">
                <label >Pecho/Busto:</label>
                  <input type="text" placeholder="Pecho/Busto (cm) " onChange={onChange} name="pecho" required/>
                </div>
                <div class="col col-md col-lg">
                <label >Cintura</label>
                  <input type="text" placeholder="Cintura (cm) " onChange={onChange} name="cintura" required/>
                </div>
                <div class="col col-md col-lg">
                <label >Abdomen</label>
                  <input type="text" placeholder="Abdomen (cm) " onChange={onChange} name="abdomen" required/>
                </div>
              </div>


            <div class="row">
                <div class="col col-md col-lg">
                <label >Cadera</label>
                  <input type="text" placeholder="Cadera (cm)" onChange={onChange} name="cadera" required/>
                </div>
                <div class="col col-md col-lg">
                <label >Glúteo</label>
                  <input type="text" placeholder="Gluteo (cm)" onChange={onChange} name="gluteo" required/>
                </div>
                <div class="col col-md col-lg">
                <label >Pierna</label>
                  <input type="text" placeholder="Pierna (cm)" onChange={onChange} name="pierna" required/>
                </div>
                <div class="col col-md col-lg">
              <label >Grasa visceral:</label>
                <input type="text" placeholder="% de grasa" onChange={onChange} name="grasav" required />
              </div>
              <div class="col col-md col-lg">
              <label >Edad metabolica:</label>
                <input type="text" placeholder="Edad" onChange={onChange} name="edadmetabolica" required />
              </div>
                <div class="col col-md col-lg">
                <label >Paciente:</label>
                <select style={{width: "100%", height:"100%", fontSize: 16}} class="form-select form-select-sm" aria-label=".form-select-sm example" name="email"  onChange={onChange}  required>
  <option selected disabled value="seleccion">Seleccionar paciente</option>

  {map(correoUser).map((item, index) => (
    <option value={item.email}>{item.nombre}</option>
                         ) )}
  
  
</select>
                  </div>
              </div>
            
        </div>

    </div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn  me-md-2" onClick={enviar} type="submit">Registrar</button>
        <button class="btn" type="button" onClick={volver}>Cancelar</button>
        </div>
      </form>
      </div>
        </>
    )
}


export default GainsForm;

function defaultFormValue(){
    return{
        email: " ",
        abdomen: "",
        cadera: "",
        cintura: "",
        edadm: "",
        gluteo: "",
        grasa: "",
        imc: "",
        musculo: "",
        pecho: "",
        peso: "",
        pierna: "",
        fecha: "",
        hora: "",
        grasav: "",
        edadmetabolica: "",
    }
}
