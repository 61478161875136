import React from 'react';
import  perfil from "../assets/img/pr.png";
import '../styles/contInicio.css';


function YoSoy({history}) {
    function sobremi() {
        history.push("/Sobre_mi");
    }
    return (
        <>
            <section class="acerca">

<h1 class="encabezado">Acerca</h1>

<div class="columna-acerca">


    <div class="img-acerca">
        <img src={perfil} alt=""/>
    </div>

    <div class="contenido-acerca">
        <h3>Yo soy Giovanna Rangel Murillo</h3>
        <p>Desde que egrese como Licenciada en nutrición en la Universidad de Guadalajara en el 2012, empecé a trabajar en una clínica de control de peso, y esa experiencia me sirvió para empezar ese mismo año a consultar desde la casa de mis papas en las mañanas y por las tardes en el consultorio en una cadena de Gimnasios importante, llevando a la par mi programa “Nutrición a tu empresa, cambiando hábitos”, programa que con orgullo sigue vigente.
En el 2015, un grupo de colegas y yo, creamos una clínica de nutrición y medicina, que me ayudo a seguir creciendo profesionalmente. Inicie el 2019 como nutrióloga de las fuerzas básicas del FC Toluca y mismo año inicie la Maestría en nutrición deportiva en la Universidad del Valle de Mexico.
Como a muchos, la pandemia que inicio en Marzo del 2019 fue un parteaguas en mi crecimiento y en mi independencia como profesionista,  y fue así que poco a poco, fui desarrollando y definiendo mi proyecto, de lo que después se convertiría en mi presente.  
En Febrero 2021 registré el nombre “VO2 nutrition by Giovanna Rangel”, mi propia clínica de nutrición deportiva, en agosto egrese de la maestría, y en septiembre quedo terminadas las nuevas instalaciones de VO2 nutrition.
</p>


            <div class="botones-acerca">
                <a onClick={sobremi} class="btn-home">Más info</a>
            </div>
            <br/>
    </div>

</div>

</section>

        </>
    )
}

export default YoSoy;


/* 

import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import  facebook from "../assets/img/facebook.png";
import  instagram from "../assets/img/instagram.png";
import  unirse from "../assets/img/unirse.png";
import  perfil from "../assets/img/perfil.jpeg";


function YoSoy() {
    return (
        <>
            <div class="container-fluid acerca">
    <div class="izquierda-sidebar"></div>

    <div class="row">
        <div class="columna-izquierda">
            <img src={perfil} alt=""/>
        </div>
        <div class="columna-derecha">
            <h1>Giovanna Rangel Murillo</h1>
            <p style={{color: "#fff"}}>
                
</p>

                <div class="social-media">
                    <div class="icono">
                        <img src={facebook} alt=""/>
                        <a href="https://www.facebook.com/VO2-Nutrition-by-Giovanna-Rangel-106725840997039">
                            <img src={unirse} alt=""/>
                        </a>
                    </div>

                    <div class="icono">
                        <img src={instagram} alt=""/>
                        <a href="https://instagram.com/vo2nutritionbygiovannarangel?utm_medium=copy_link">
                            <img src={unirse} alt=""/>
                        </a>
                    </div>

                    <div class="icono">
                        <img src={unirse} alt=""/>
                        <a href="#">
                            <img src={unirse} alt=""/>
                        </a>
                    </div>
                </div>
        </div>
    </div>
</div>
        </>
    )
}

export default YoSoy;


*/