import React from 'react';
import { map } from "lodash";



export const Resultados = ({resul, setFlag, setRes}) => {
    const volver = () => {
        setRes(false);
        setFlag(false);
      }

    return (
        <>
          <div class="table-responsive">
        <table class="table table-responsive table-striped"  style={{fontSize: 15}}>
            <thead  style={{background: "#78bf8a", fontWeight: "bold"}}>
                <tr style={{color: "#fff"}}>
                <th style={{color: "#fff"}} scope="col">Fecha</th>
                <th style={{color: "#fff"}} scope="col">Peso</th>
                <th style={{color: "#fff"}} scope="col">Grasa</th>
                <th style={{color: "#fff"}} scope="col">Hueso</th>
                <th style={{color: "#fff"}} scope="col">Músculo</th>
                <th style={{color: "#fff"}} scope="col">Agua</th>
                <th style={{color: "#fff"}} scope="col">Pecho</th>
                <th style={{color: "#fff"}} scope="col">Cintura</th>
                <th style={{color: "#fff"}} scope="col">Abdomen</th>
                <th style={{color: "#fff"}} scope="col">Cadera</th>
                <th style={{color: "#fff"}} scope="col">Glúteo</th>
                <th style={{color: "#fff"}} scope="col">Pierna</th>

                </tr>
            </thead>
                <tbody>
                    {map(resul).map((item, index) => (
                        <tr key={index}>
                        <th style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}} scope="row">{item.fecha}</th>
                        <td style={{color: "#000", background: "#dcffc1", fontWeight: "bold"}}>{item.peso}</td>
                        <td style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}}>{item.grasa}</td>
                        <td style={{color: "#000", background: "#dcffc1", fontWeight: "bold"}}>{item.imc} </td>
                        <td style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}}>{item.musculo} </td>
                        <td style={{color: "#000", background: "#dcffc1", fontWeight: "bold"}}>{item.edadm} </td>
                        <td style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}}>{item.pecho} </td>
                        <td style={{color: "#000", background: "#dcffc1", fontWeight: "bold"}}>{item.cintura} </td>
                        <td style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}}>{item.abdomen} </td>
                        <td style={{color: "#000", background: "#dcffc1", fontWeight: "bold"}}>{item.cadera} </td>
                        <td style={{color: "#000", background: "#dcffb9", fontWeight: "bold"}}>{item.gluteo} </td>
                        <td style={{color: "#000", background: "#dcffc1", fontWeight: "bold"}}>{item.pierna} </td>



               </tr>
                    ) )}

                </tbody>
        </table>
        </div>
        <button class="btn " onClick={volver} type="button">Volver</button>
        </>
    )
}


