import React from 'react'
import logo from "../assets/icons/css/all.css";
import '../styles/contInicio.css'; 

function Modos({history}) {
  function servicios() {
    history.push("/servicios");
}
    return (
        <>
        <section class="contenido-caracteristicas">

<h1 class="encabezado"></h1>

<div class="box-container-caracterisiticas">
    <div class="box-caracteristicas">
        <i class="iconos-cosas fas fa-user"></i>
        <h3>Consulta presencial</h3>
        <br/>
        <br/>
        <a onClick={servicios} class="btn-home">Ver mas</a>
    </div>

    <div class="box-caracteristicas">
        <i class="iconos-cosas fas fa-laptop"></i>
        <h3>Consulta en linea</h3>
        <br/>
        <br/>
        <a onClick={servicios} class="btn-home">Ver mas</a>
    </div>

    <div class="box-caracteristicas">
        <i class="iconos-cosas fas fa-building"></i>
        <h3>Nutrición para tu empresa</h3>
        <br/>
        <br/>
        <a onClick={servicios}  class="btn-home">Ver mas</a>
    </div>
</div>

</section>
        </>
    )
}

export default Modos;
