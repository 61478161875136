import React, {useState, useEffect} from 'react'
import * as firebase from "firebase";
import 'firebase/auth';
import { firebaseApp } from "../firebase/Firebase";
import "firebase/firestore";
import DietForm from '../formularios/DietForm';
import GainsForm from '../formularios/GainsForm';
import { Menu } from '../formularios/Menu';
import '../styles/logUsuario.css';
import '../styles/sesion.css';
import fondo1 from "../assets/img/ses1.jpg";
import fondo3 from "../assets/img/ses3.jpg";
import qr from "../assets/img/qr.png";
import Pie from '../inicio/Pie';
import toast, { Toaster } from 'react-hot-toast';
import { Plan } from './Plan';
import { Resultados } from './Resultados';
import Recordatorios from '../formularios/Recordatorios';
import Notificaciones from '../formularios/Notificaciones';
const db = firebase.firestore(firebaseApp);

export const UserLogged = ({setFlagLogin, history, setIsAuthenticated, isAuthenticated}) => {
    const user =  firebase.auth().currentUser;
    const [gains, setGains] = useState(null);
    const [resul, setResul] = useState([]);
    const [dataPlan, setDataPlan] = useState([]);
    

    useEffect(() => {
        const resultRes = [];

        db.collection("resultados")
          .doc(`${user.email}`)
          .collection("resultados")
          .orderBy("createAt", "desc")
          .get()
          .then((response) => {
              setGains(response.docs[response.docs.length - 1]);
  
            response.forEach((doc) => {
              const resultados = doc.data();
              resultados.id = doc.id;
              resultRes.push(resultados);
            });
            setResul(resultRes);
          });
          
          db.collection("plan")
          .doc(`${user.email}`)
          .get()
          .then((response) => {
          setDataPlan(response.data());
          });

    }, [])

    
    const [res, setRes] = useState(false);
    const [plan, setPlan] = useState(false);
    const [menu, setMenu] = useState(false);
    const [flag, setFlag] = useState(false);
    const [recordatorio, setRecordatorio] = useState(false);
    const [notifi, setNotifi] = useState(false);


    const FormReco = () => {
        setRecordatorio(true);
        setFlag(true);
     }
     function citas() {
      history.push("/VO2_NUTRITION/Citas");
  }
   

 const FormPlan = () => {
    setPlan(true);
    setFlag(true);
 }

 function planNutricional() {
  history.push("/VO2_NUTRITION/Plan_Nutricional");
}

 const FormRes = () => {
    setFlag(true);
    setRes(true);
 }

 function resultadosForm() {
  history.push("/VO2_NUTRITION/Resultados");
}

 const FornNotifi = () => {
  setNotifi(true);
  setFlag(true);
}

 const CerrarSesion = () => {
  firebase.auth().signOut();
  setFlagLogin(false);
  setIsAuthenticated(false);
}
 
const notification = () =>{
    toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5" style={{textAlign: "center"}}>
                <img style={{width: "150px" , height: "150px"}}
                  className="h-10 w-10 rounded-full"
                  src="https://firebasestorage.googleapis.com/v0/b/vo2-nutrition.appspot.com/o/avatar%2FweUZJYAwpkVmm1ZHCg1cmsJplNk1?alt=media&token=0aadc7ef-daa7-49d5-8c55-a1d0ca3e9cc5"
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1" style={{textAlign: "center"}}>
                <p className="text-sm font-medium text-gray-900">
                  Alvaro Márquez
                </p>
                <p className="mt-1 text-sm text-gray-500">
                Hola Gio! Confirmo mi cita del dia <br/> 05/06/2022 a las 5:00 pm 😁 
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
            style={{backgroundColor: "#f2a7bb", color: "White" }}
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Cerrar
            </button>
          </div>
        </div>
      ))
}


  

    if(user.uid == "0lFaseJdyHc3Qpt7gQnvP4MVCZp2"){
      setIsAuthenticated(true);
        return( 
            <>
            {(flag == false ?

<div class="navegacion-sesion">
<Toaster
  position="bottom-right"
  reverseOrder={false}
  toastOptions={{
    className: '',
    duration: 10000,}}
/>
<div class="navArea">
    <ul>
        <li>
        <a style={{textAlign: "center"}}>Bienvenida Gio</a>
        </li>
        <div style={{textAlign: "center"}} >
        <img style={{width: "150px", height: "150px", borderRadius: "50%"}} src={user.photoURL} />
        </div>
        <br/>
        <li>
            <a onClick={citas} >Citas</a>
        </li>
        <li>
            <a onClick={resultadosForm} >Resultados</a>
        </li>
        <li>
            <a onClick={planNutricional} >Plan Nutricional</a>
        </li>
      { // <li>
          //  <a onClick={FornNotifi}>Notificaciones</a>
        //</li> 
        }
        <li>
        <a onClick={CerrarSesion}>Cerrar sesión</a>
        </li>
    </ul>
</div>
<div class="imgArea">
    <img src={fondo3} />
</div>
</div>
              
    : <div> {(plan && <DietForm user={user} setFlag={setFlag} setPlan={setPlan}/>)}
    {(res && <GainsForm  setFlag={setFlag} setRes={setRes}/>)}
    {(menu && <Menu setMenu={setMenu}/>)}
    {(recordatorio && <Recordatorios  setFlag={setFlag} setRecordatorio={setRecordatorio}/>)}
    {(notifi && <Notificaciones  setFlag={setFlag} setNotifi={setNotifi}/>)}
    </div>
    )}
            </>
        )
    }


    return (
        <>

{(flag == false ?

<div class="navegacion-sesion">
<div class="navArea">
    <ul>
        <li>
        <h1 style={{color: "#fff", textAlign: "center"}}>¡Hola {user.displayName}!</h1>
        <div style={{textAlign: "center"}} >
        <img style={{width: "150px", height: "150px", borderRadius: "50%"}} src={user.photoURL} />
        </div>
        <br/>
        </li>
        <li>
            <a onClick={FormRes} >Resultados</a>
        </li>
        <li>
            <a onClick={FormPlan} >Plan Nutricional</a>
        </li>
        <li>
            <a onClick={CerrarSesion}>Cerrar sesión</a>
        </li>
    </ul>
</div>
<div class="imgArea">
    <img src={fondo1} />
</div>
</div>
              
    : <div> {(plan && <Plan dataPlan={dataPlan} setFlag={setFlag} setPlan={setPlan}/>)}
    {(res && <Resultados resul={resul}  setFlag={setFlag} setRes={setRes}/>)}
    {(menu && <Menu setMenu={setMenu}/>)}
    </div>
    )}
        

<Pie history={history}/>
        </>
        
    )
}

/*
<li>
  <a onClick={notification}>Notificación</a>
</li>
         */