import React from 'react';
import "../styles/gale.css";
import imga from "../assets/img/logo.jpeg";
import fondo from "../assets/img/wea-doble.png";
import img1 from "../assets/img/imgvo (1).jpeg";
import img2 from "../assets/img/imgvo (2).jpeg";
import img3 from "../assets/img/imgvo (3).jpeg";
import img4 from "../assets/img/imgvo (4).jpeg";
import img5 from "../assets/img/imgvo (5).jpeg";
import img6 from "../assets/img/imgvo (6).jpeg";
import img7 from "../assets/img/imgvo (7).jpeg";
import img8 from "../assets/img/imgvo (8).jpeg";
import img9 from "../assets/img/imgvo (9).jpeg";




export const MisAtletas = () => {

    const img = () => {
        document.querySelectorAll(".contenedor-imagenes img").forEach(gale =>{

            gale.onclick = () =>{
             document.querySelector(".pop-imagen").style.display = 'block';
             document.querySelector(".pop-imagen img").src = gale.getAttribute("src");
            }
         });
     
         document.querySelector(".pop-imagen span").onclick = () =>{
             document.querySelector(".pop-imagen").style.display = 'none';
         }
    }
    
    

    return (
        <>
        <div class="gale"><img style={{ display: "flex", alignItems: "center",   backgroundSize: "cover",
        backgroundPosition: "center"

        }} class="d-block img-fluid" src={fondo} onClick={fondo} alt=""/></div>
           
<br/>
<br/>

<div class="contenedor-gale">

<h1 class="encabezado">Mis Atletas</h1>

<div class="contenedor-imagenes">
<div class="gale"><img src={img1} onClick={img} alt=""/></div>
<div class="gale"><img src={img2} onClick={img} alt=""/></div>
<div class="gale"><img src={img3} onClick={img} alt=""/></div>
<div class="gale"><img src={img4} onClick={img} alt=""/></div>
<div class="gale"><img src={img5} onClick={img} alt=""/></div>
<div class="gale"><img src={img6} onClick={img} alt=""/></div>
<div class="gale"><img src={img7} onClick={img} alt=""/></div>
<div class="gale"><img src={img8} onClick={img} alt=""/></div>
<div class="gale"><img src={img9} onClick={img} alt=""/></div>
</div>



<div class="pop-imagen">
<span>&times;</span>
<img src="imagenes-inicio/team.jpeg" alt=""/>
</div>


</div>

<br/>
<br/> 
        </>
    )
}
