import React from 'react'
import Pie from '../inicio/Pie'
import { MisAtletas } from './MisAtletas'

export const PantallaMisAtletas = ({history}) => {
    return (
        <>
            <MisAtletas history={history}/>
            <Pie history={history} />
        </>
    )
}
