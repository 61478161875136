import React from 'react';
import "../styles/contInicio.css";
import us from "../assets/img/us.jpg";

function Referencias() {

    return (
        <>
            <section class="comentarios">
         <h1 class="encabezado">Lo que mís pacientes dicen</h1>

         <div class="box-container-caracterisiticas">

         <div style={{height: "400px" }} class="box-caracteristicas">
             <i class="fas fa-quote-right"></i>
             <div class="usuario-review">
             <img src={us} alt=""/>
                 <h3>Estrella Martinez</h3>
                 <div class="estrellas">
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star"></i>
                     <i class="fas fa-star-half-alt"></i>
                 </div>
                 <div class="comentarios-review">
                 La atención es súper buena, la nutrióloga Giovanna es excelente, los resultados son 
                 inmediatos y sus menús son súper amigables, el lugar muy fácil de llegar, tiene amplio 
                 estacionamiento y la limpieza del consultorio es muy notoria! La recomiendo al 100 😀
                 </div>
             </div>
         </div>

         <div style={{height: "400px" }} class="box-caracteristicas">
            <i class="fas fa-quote-right"></i>
            <div class="usuario-review">
            <img src={us} alt=""/>
                <h3>Lupita Villalvazo</h3>
                <div class="estrellas">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </div>
                <div class="comentarios-review">
                ¡La mejor nutrióloga! Sólo tengo palabras de agradecimiento para su trabajo; excelente 
                persona, y la mejor atención. Sin duda alguna 100% recomendada. 🥰
                </div>
            </div>
        </div>

        <div style={{height: "400px" }} class="box-caracteristicas">
            <i class="fas fa-quote-right"></i>
            <div class="usuario-review">
                <img src={us} alt=""/>
                <h3>Magaly Glez</h3>
                <div class="estrellas">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </div>
                <div class="comentarios-review">
                Es una gran nutrióloga, excelente persona y súper profesional. Siempre es muy amable y 
                siempre te motiva a seguir adelante. El consultorio tiene un ambiente muy cómodo y cálido. 
                ¡La recomiendo ampliamente!
                </div>
            </div>
        </div>

        </div>
     </section>
        </>
    )
}

export default Referencias;


/*

import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

function Referencias() {

    const slide = document.getElementById("slide");
const flechaArriba = document.getElementById("flecha-arriba");
const flechaAbajo = document.getElementById("flecha-abajo");

let x = 0;

flechaArriba.addEventListener("click", ()=>{
    if(x > "-900"){
    x = x - 300;
    slide.style.top = x + "px";
    }
    
});

flechaAbajo.addEventListener("click", ()=>{
    if(x < 0){
    x = x + 300;
    slide.style.top = x + "px";
    }
    
});

    return (
        <>
            <div class="heroe">
        <h1 class="reseña">Reseñas</h1>
        <div class="review-box">
            <div id="slide">
                <div class="card">
                    <div class="perfil">
                        <img src="flechas-caras/pic1.jpg" alt=""/>
                        <div>
                        <h3>Sakura Chan</h3>
                        <p>Chunin de la aldea de la hoja</p>
                        </div>
                        
                    </div>
                   
                    <p>veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                         Nemo enim ipsam voluptatem quia voluptas sit aspernatur 
                         aut odit aut fugit, sed quia consequuntur </p>
                </div>

                <div class="card">
                    <div class="perfil">
                        <img src="flechas-caras/pic4.jpg" alt=""/>
                        <div>
                        <h3>Alvin Yaquitori</h3>
                        <p>Corredor de sushi</p>
                        </div>
                        
                    </div>
                   
                    <p>veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                         Nemo enim ipsam voluptatem quia voluptas sit aspernatur 
                         aut odit aut fugit, sed quia consequuntur </p>
                </div>

                <div class="card">
                    <div class="perfil">
                        <img src="flechas-caras/pic2.jpg" alt=""/>
                        <div>
                        <h3>Jefferson Gutierritos</h3>
                        <p>Interpol</p>
                        </div>
                        
                    </div>
                   
                    <p>veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                         Nemo enim ipsam voluptatem quia voluptas sit aspernatur 
                         aut odit aut fugit, sed quia consequuntur </p>
                </div>

                <div class="card">
                    <div class="perfil">
                        <img src="flechas-caras/pic3.jpg" alt=""/>
                        <div>
                        <h3>Sharpay Evans</h3>
                        <p>Cantante</p>
                        </div>
                        
                    </div>
                   
                    <p>veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                         Nemo enim ipsam voluptatem quia voluptas sit aspernatur 
                         aut odit aut fugit, sed quia consequuntur </p>
                </div>


            </div>

            <div class="sidebar">
                <img src="flechas-caras/up-arrow.png" alt="" id="flecha-arriba"/>
                <img src="flechas-caras/down-arrow.png" alt="" id="flecha-abajo"/>
            </div>

        </div>

        <br/>
        <a href=""><button class="buton-galeria button2">Ver Galeria</button></a>
    </div> 
        </>
    )
}

export default Referencias;


*/